import React from "react";
import "./Education.css";

const Education = () => {
  return (
    <section id="education" className="education">
      <h2 className="heading">
        My <span>Journey</span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>

      <div className="education-row">
        <div className="education-column">
          <h3 className="title">
            Education{" "}
            <span className="animate scroll" style={{ "--i": 2 }}></span>
          </h3>
          <div className="education-box">
            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i className="bx bx-calendar"></i> 2019 - 2020
                </div>
                <h3>High School Education - SCSD </h3>
                <p>
                  During my 12th standard education in Electronics Engineering
                  at New Delhi's Sector-9, I excelled academically, securing an
                  impressive 85% in my final examinations. This educational
                  experience laid the foundation for my passion for electronics
                  and technology. It provided me with valuable knowledge and
                  problem-solving skills that I continue to apply in my academic
                  and professional journey.
                </p>
              </div>
            </div>
            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i className="bx bx-calendar"></i> 2020 - 2023
                </div>
                <h3>Bechelor Degree - GGSIPU </h3>
                <p>
                  I am proud to hold a Bachelor's degree in Computer
                  Applications (BCA) from the prestigious Vivekananda Institute
                  of Professional Studies, located in the vibrant city of New
                  Delhi, Haiderpur. Over the course of three enriching years, I
                  dedicated myself to academic excellence, resulting in an
                  outstanding CGPA of 9.5. This journey has equipped me with a
                  strong foundation in computer science, programming, and IT
                  management.
                </p>
                <p>
                  It has honed my problem-solving abilities and deepened my
                  passion for technology. My BCA degree has been a
                  transformative experience, and I am excited to leverage the
                  skills and knowledge gained during this period to contribute
                  meaningfully to the world of technology and innovation.
                </p>
              </div>
            </div>
            <span className="animate scroll" style={{ "--i": 3 }}></span>
          </div>
        </div>

        <div className="education-column">
          <h3 className="title">
            Experience
            <span className="animate scroll" style={{ "--i": 5 }}></span>
          </h3>
          <div className="education-box">
            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i className="bx bx-calendar"></i> Aug 2021 - Jan 2022
                </div>
                <h3>Web developer - RedPositive Service OPC Pvt. Ltd</h3>
                <p>
                  India As a Full Stack Software Engineer at Repositive, a
                  leading service-based company, I contributed to the
                  development and implementation of innovative solutions
                  leveraging the MERN (MongoDB, Express.js, React.js, Node.js)
                  stack. With a focus on delivering exceptional service
                  experiences, I played an integral role in building robust and
                  scalable software solutions that empower clients in their
                  data-driven endeavors.{" "}
                </p>
              </div>
            </div>
            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i className="bx bx-calendar"></i> Feb 2022 - Jul 2022
                </div>
                <h3>FullStack developer - Myways.ai</h3>
                <p>
                  As a Full Stack Software Engineer at Myways.ai, I played a key
                  role in the successful migration of the backend infrastructure
                  from Node.js to AWS Lambda, contributing to the development
                  and implementation of a cutting-edge platform focused on
                  education empowerment. Leveraging my expertise in the MERN
                  (MongoDB, Express.js, React.js, Node.js) stack and AWS Lambda,
                  I helped optimize the platform's performance, scalability, and
                  cost-efficiency.
                </p>
              </div>
            </div>
            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i className="bx bx-calendar"></i> Jul 2022 - Present
                </div>
                <h3>FullStack Developer - Linkly</h3>
                <p>
                  As a Full Stack Software Engineer at Linkly, I play a pivotal
                  role in the development and creation of an innovative platform
                  that enables organizations to create, sell, and negotiate
                  backlinks. Leveraging my expertise in the MERN (SQL,
                  Express.js, React.js, Node.js) stack, I contribute to the
                  entire software development life cycle, from conceptualization
                  to implementation, ensuring a seamless and efficient user
                  experience.
                </p>
              </div>
            </div>

            <div className="education-content">
              <div className="content">
                <div className="year">
                  <i className="bx bx-calendar"></i> Jan 2023 - Present
                </div>
                <h3>Software Engineer - SeoBox.Ai</h3>
                <p>
                  As a passionate FullStack Engineer at seobox.ai, I embark on a
                  daily journey of transforming ideas into reality. My role
                  involves crafting robust product logic, leading a dynamic
                  team, meticulously managing code repositories, and ensuring
                  top-notch customer support. I thrive in this engineering role
                  where every line of code contributes to our mission of
                  revolutionizing the digital landscape. Join me on this
                  exciting voyage of innovation, where we're shaping the future,
                  one algorithm at a time. Connect with me to explore how we can
                  collaborate and drive meaningful change together.
                </p>
              </div>
            </div>
            <span className="animate scroll" style={{ "--i": 6 }}></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Education;
