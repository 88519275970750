import React, { useEffect, useState } from "react";
import "./Header.css";
const Header = () => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    let allNavLinks = document.querySelectorAll(".navbar a");
    allNavLinks.forEach((link) => {
      link.addEventListener("click", function (e) {
        document.getElementsByClassName("navbar")[0].classList.remove("active");
        document.getElementById("menu-icon").classList.remove("bx-x");
        document.getElementById("menu-icon").classList.add("bx-menu");
      });
    });
  }, []);

  useEffect(() => {
    window.onscroll = function () {
      let header = document.querySelector(".header");
      header.classList.toggle("sticky", window.scrollY > 100);

      let allNavLinks = document.querySelectorAll(".navbar a");
      allNavLinks.forEach((link) => {
        link.addEventListener("click", function (e) {
          link.classList.remove("active");
        });
      });
      let allSections = document.querySelectorAll("section");
      allNavLinks.forEach((link) => {
        // link.classList.toggle("active", window.scrollY > 100);
        let top = window.scrollY;
        allSections?.forEach((section) => {
          let offset = section?.offsetTop;
          if (
            top >= offset - 300 &&
            top < offset + section?.offsetHeight - 300
          ) {
            let id = section?.getAttribute("id");
            allNavLinks?.forEach((link) => {
              link.classList.remove("active");
            });
            document
              ?.querySelector(".navbar a[href*=" + id + "]")
              ?.classList?.add("active");

            section?.classList?.add("show-animate");
          } else {
            section?.classList?.remove("show-animate");
          }
        });
      });
    };
  }, []);
  return (
    <header className="header">
      <a href="#" className="logo">
        Ankur. <span className="animate" style={{ "--i:": 1 }}></span>
      </a>
      {/* <box-icon id="menu-icon" name="menu"></box-icon> */}
      <i
        className="bx bx-menu"
        onClick={(e) => {
          if (e.target.classList.contains("bx-menu")) {
            e.target.classList.remove("bx-menu");
            e.target.classList.add("bx-x");
            document
              .getElementsByClassName("navbar")[0]
              .classList.add("active");
          } else {
            e.target.classList.remove("bx-x");
            e.target.classList.add("bx-menu");
            document
              .getElementsByClassName("navbar")[0]
              .classList.remove("active");
          }
        }}
        id="menu-icon"
      >
        <span className="animate" style={{ "--i:": 2 }}></span>
      </i>
      <nav className="navbar">
        <span className="animate" style={{ "--i:": 2 }}></span>
        <a href="#home" className="">
          Home
        </a>
        <a href="#about">About</a>
        <a href="#education">Education</a>
        <a href="#skills">Skills</a>
        <a href="#contact">Contact</a>
      </nav>
    </header>
  );
};

export default Header;
