import React from "react";
import "./About.css";
const About = () => {
  return (
    <section id="about" className="about">
      <h2 className="heading">
        About <span>Me</span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>

      <div className="about-img">
        <img src="/assets/ankur.jpg" alt="myImage"></img>
        <span className="circle-spin"></span>
        <span className="animate scroll" style={{ "--i": 2 }}></span>
      </div>
      <div className="about-content">
        <h3>
          Full Stack Developer!{" "}
          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </h3>
        <p>
          As a seasoned software developer with a track record of 2+ years, I
          proudly hold a Bachelor's degree in Computer Science from the esteemed
          IP University (Class of 2023). My proficiency extends across diverse
          software domains, including Frontend, Backend, DevOps, and beyond,
          encompassing a wide array of technologies within each discipline. My
          career thrives in fast-paced startup cultures, where I've actively
          shaped the success of 4+ pioneering products. Currently, I stand as
          the Founding Engineer at Linkly Digital, spearheading technological
          excellence and innovation with unwavering determination.
          <span className="animate scroll" style={{ "--i": 4 }}></span>
        </p>

        <div className="btn-box btns">
          <a href="#" className="btn">
            Read More
          </a>
          <span className="animate scroll" style={{ "--i": 5 }}></span>
        </div>
      </div>
    </section>
  );
};

export default About;
