import React from "react";
import "./Skills.css";
const Skills = () => {
  return (
    <section id="skills" className="skills">
      <h2 className="heading inline_heading">
        Skills <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>
      <div className="skills-row">
        <div className="skills-column">
          <h3 className="title">
            Coding Skills{" "}
            <span className="animate scroll" style={{ "--i": 2 }}></span>
          </h3>
          <div className="skills-box">
            <div className="skills-content">
              <span className="animate scroll" style={{ "--i": 3 }}></span>
              <div className="progress">
                <h3>
                  HTML / CSS <span>95%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "95%" }}></span>
                </div>
              </div>
              <div className="progress">
                <h3>
                  Javascript / Python / PHP <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>
              <div className="progress">
                <h3>
                  C / C++ / Java <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  TypeScript <span>85%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "85%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  ReactJs / NextJs <span>95%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "95%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Angular <span>80%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "80%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  NodeJs / ExpressJs <span>95%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "95%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  MongoDB / SQL / Firebase DB <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="skills-column">
          <h3 className="title">
            Professional Skills{" "}
            <span className="animate scroll" style={{ "--i": 4 }}></span>
          </h3>
          <div className="skills-box">
            <div className="skills-content">
              <span className="animate scroll" style={{ "--i": 6 }}></span>
              <div className="progress">
                <h3>
                  Software Development <span>85%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "85%" }}></span>
                </div>
              </div>
              <div className="progress">
                <h3>
                  Database Management <span>85%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "85%" }}></span>
                </div>
              </div>
              <div className="progress">
                <h3>
                  Version Control <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Problem Solving <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Cloud Computing <span>70%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "70%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Frameworks And Library <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Software Testing <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>

              <div className="progress">
                <h3>
                  Data Structure And Algorithms <span>90%</span>
                </h3>
                <div className="bar">
                  <span style={{ width: "90%" }}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
