import Header from "./component/Header/Header";
import Home from "./component/Home/Home";
import About from "./component/About/About";
import Contact from "./component/Contact/Contact";
import "./App.css";
import Education from "./component/Education/Education";
import Skills from "./component/Skills/Skills";
import Footer from "./Footer/Footer";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    window.emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);
  return (
    <div>
      <Header />
      <Home />
      <About />
      <Education />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
