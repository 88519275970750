import React, { useRef } from "react";
import "./Contact.css";

const Contact = () => {
  console.log(process.env.REACT_APP_SERVICE);
  const userName = useRef();
  const userEmail = useRef();
  const userPhone = useRef();
  const userMessage = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    window.emailjs
      .send(process.env.REACT_APP_SERVICE, process.env.REACT_APP_USER_TEMP, {
        to_email: userEmail.current.value,
        name: userName.current.value,
      })
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (err) {
          console.log("FAILED...", err);
        }
      );

    window.emailjs
      .send(process.env.REACT_APP_SERVICE, process.env.REACT_APP_MY_TEMP, {
        query: userMessage.current.value,
        user_mail: userEmail.current.value,
        user_name: userName.current.value,
        user_phone: userPhone.current.value,
      })
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (err) {
          console.log("FAILED...", err);
        }
      );
    window.alert(
      "Thank you for contacting me I will get back to you as soon as possible. Have a nice day!"
    );
    userName.current.value = "";
    userEmail.current.value = "";
    userPhone.current.value = "";
    userMessage.current.value = "";
  };

  // Thank you for contacting me I will get back to you as soon as possible. Have a nice day!

  return (
    <section id="contact" className="contact">
      <h2 className="heading inline_heading">
        Contact <span className="">Me!</span>
        <span className="animate scroll" style={{ "--i": 1 }}></span>
      </h2>
      <form action="#">
        <div className="input-box">
          <div className="input-field">
            <input
              type="text"
              placeholder="Full Name"
              required
              ref={userName}
            />
            <span className="focus"></span>
          </div>

          <div className="input-field">
            <input
              type="text"
              placeholder="Email Address"
              required
              ref={userEmail}
            />
            <span className="focus"></span>
          </div>

          <span className="animate scroll" style={{ "--i": 2 }}></span>
        </div>

        <div className="input-box">
          <div className="input-field">
            <input
              type="number"
              placeholder="Mobile Number"
              required
              ref={userPhone}
            />
            <span className="focus"></span>
          </div>

          <div className="input-field">
            <input type="text" placeholder="Email Subject" required />
            <span className="focus"></span>
          </div>

          <span className="animate scroll" style={{ "--i": 3 }}></span>
        </div>

        <div className="textarea-field">
          <textarea
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder="Your Message"
            required
            ref={userMessage}
          ></textarea>
          <span className="focus"></span>

          <span className="animate scroll" style={{ "--i": 4 }}></span>
        </div>

        <div className="btn-box btns">
          <button onClick={sendEmail} className="btn">
            Submit
          </button>

          <span className="animate scroll" style={{ "--i": 5 }}></span>
        </div>
      </form>
    </section>
  );
};

export default Contact;
