import React from "react";
import "./Home.css";
const Home = () => {
  return (
    <section className="home show-animate" id="home">
      <div className="home-content">
        <h1>
          Hi, I'm <span>Ankur Chaurasia</span>{" "}
          <span className="animate" style={{ "--i": 2 }}></span>
        </h1>
        <div className="text-animate">
          <h3>Software Developer</h3>
          <span className="animate" style={{ "--i": 3 }}></span>
        </div>
        <p>
          Discover Ankur Chaurasia, a passionate software developer with a
          Bachelor's degree from IP University. Explore his dynamic career,
          leaving an impact on over four companies as he crafts real-world
          applications.<span className="animate" style={{ "--i": 4 }}></span>
        </p>

        <div className="btn-box">
          <a href="#contact" className="btn">
            Let's Talk{" "}
          </a>
          <a href="#contact" className="btn">
            Download CV{" "}
          </a>
          <span className="animate" style={{ "--i": 5 }}></span>
        </div>
      </div>
      <div className="home-sci">
        <a
          href="https://github.com/Ankur-2002"
          target="_blank"
          rel="noreferrer"
        >
          <i class="bx bxl-github"></i>
        </a>
        <a href="mailto:ankurchaurasia29@gmail.com">
          <i class="bx bxl-gmail"></i>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/ankur-chaurasia-663682211/"
        >
          <i class="bx bxl-linkedin"></i>
        </a>
        <span className="animate" style={{ "--i": 6 }}></span>
      </div>

      <div className="home-imgHover"></div>
      <span className="animate home-img" style={{ "--i": 7 }}></span>
    </section>
  );
};

export default Home;
